module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alex Fenwood Hughes personal site","short_name":"Alex Fenwood Hughes","start_url":"/","display":"minimal-ui","background_color":"#FFFFFF","theme_color":"#000000","icon":"src/images/favicon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c68d3defd265f65def770a15cc121ea4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
